.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 2em;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color:var(--color-primary);
    text-decoration: none;
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary);
    text-decoration: underline;
}
.portal-teaser__text ul,
.wysiwyg ul{
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}
.portal-teaser__text ul li,
.wysiwyg ul li,
.wysiwyg ol li{
    margin-left: 0;
    padding-left: calc(25rem / 16);
    position: relative;
}
.portal-teaser__text ul li:before,
.wysiwyg ul li:before{
    content: '';
    position: absolute;
    top: calc(5rem / 16);
    left: 0;
    bottom: 0;
    width: 8px;
    height: 8px;
    background: var(--color-primary);
}

/* multicol */
.wysiwyg ul.multicol{
    column-count: 2;
    column-gap: 1rem;
}