.loading-overlay__container {
    text-align:center;
    position: absolute;
    left:50%;
    top:50%;
    transform: translateX(-50%) translateY(-50%);
}
.loading-spinner {
    width: 70px;
    margin: 0 auto;
}
.loading-spinner__item {
    width: 18px;
    height: 18px;
    background-color: #fff;

    border-radius: 100%;
    display: inline-block;
    animation: loading-spinner-rotate 1.4s infinite ease-in-out both;
}
.loading-spinner__item--1 {
    animation-delay: -0.32s;
}
.loading-spinner__item--2 {
    animation-delay: -0.16s;
}
@keyframes loading-spinner-rotate {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1.0);
    }
}