.form-errors.invalid-feedback {
    display: block;
}
.form-errors,
.invalid-feedback {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: var(--color-danger);
}
.form-errors:empty,
.is-valid .form-errors {
    display: none;
}
.form-errors ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}