.custom-checkbox {
    position: relative;
    padding-left: 2rem;
}

.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}

.custom-checkbox:after {
    clear: both;
}

.custom-checkbox>label{
    cursor: pointer;
}

.custom-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.custom-checkbox__box-bg {
    position: absolute;
    left: 0;
    margin-right: calc(7rem/16);
    overflow: hidden;
    background: linear-gradient(229.09deg, #01D0E8 0%, #5F19EB 75%, #7f21d9 100%);
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 0.125rem;
}
.custom-checkbox__box {
    position: absolute;
    left: .125rem;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: 0;
    background: #ffffff;
    width: 1.25rem;
    height: 1.25rem;
    margin-top: 0.25rem;
    border-radius: calc(4rem / 16);
    box-shadow: 0 3px 6px 0 rgba(11,11,12,0.2);
}
.custom-checkbox--without-text {
    padding-left:0;
}
.custom-checkbox--without-text .custom-checkbox__box {
    position: static;
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.75rem;
    color: var(--color-text-default);
    visibility: hidden;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
    color:#fff;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box {
    background: var(--color-primary);
}
.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
    font-size:.9375rem;
    position: relative;
    top:.375rem;
}


/* inline */
.form-check-inline .form-check-input {
    position: absolute; /* override bootstrap */
}