.content-block {
    margin:0 0 calc(75rem/16) 0;

    @media screen and (min-width: 768px) {
        margin:0 0 calc(100rem/16) 0;
    }
    @media screen and (min-width: 1500px) {
        margin:0 0 calc(150rem/16) 0;
    }
}
