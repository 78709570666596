.hero {
    margin-top:5.5rem;
    padding-bottom: calc(45rem/16);
    @media screen and (min-width: 768px) {
        padding-bottom: calc(90rem/16);
        margin-top:30vh;
    }
}


/* stage */
.hero__stage-btn {
    margin:2rem 0 3.25rem 0;
}
.hero__logos {
    @media screen and (max-width: 767px) {
        margin-bottom: calc(45rem/16);
    }
}
.hero__stage__headings {
    width: 100%;
    max-width:35rem;
}
.hero__logos-text {
    margin-right:1rem;
}
.hero__logos-img {
    width: auto;
    height:1.5rem;
}
.hero__logos-thumb {
    margin:0 1rem .5rem 0;
    display:inline-block;
}



/* box */
.hero__box {
    position: relative;
    padding:calc(60rem / 16) calc(60rem / 16) calc(80rem / 16) calc(80rem / 16);
    background-color: #FFFFFF;
    box-shadow: 0 30px 30px 0 rgba(11,11,12,0.1);
    font-size:1.5rem;
    letter-spacing: 1px;
    font-family: var(--font-default-bold);
    margin-bottom:3.75rem;
    @media (max-width: 767px) {
        padding:calc(32rem / 16) calc(24rem / 16) calc(40rem / 16) calc(24rem / 16);
    }
}
.hero__box-item {
    margin-bottom: 2.125rem;
}
.hero__box-item:last-of-type {
    margin-bottom:0;
}
.hero__box-btn {
    position: absolute;
    right:0;
    bottom:-1.375rem;
}


/* textblock */
.hero-wysiwyg {
    margin:1.5rem 0 1.625rem 0;
}