.portal-content-wrapper {
    background: #fff;
    color:var(--color-text-default);
    padding: calc(75rem/16) 0;
    @media screen and (min-width: 768px) {
        padding: calc(100rem/16) 0;
    }
    @media screen and (min-width: 1500px) {
        padding: calc(150rem/16) 0;
    }
}
.portal-teaser-wrapper {
    padding:0 0 calc(30rem/16) 0;
    @media screen and (min-width: 768px) {
        padding:0 0 calc(65rem/16) 0;
    }
    @media screen and (min-width: 1500px) {
        padding:0 0 calc(100rem/16) 0;
    }
}
.portal-teaser {
    position: relative;
    padding:1rem 0 0 4rem;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
}
.portal-teaser__nr {
    position: absolute;
    left:-4rem;
    top:-1rem;
    padding:0 0 0 4rem;
    font-family: var(--font-default-bold);
    font-size: calc(50rem/16);
}
.portal-teaser__text {
    margin:1.5rem 0 2.5rem 0;
}