.btn{
    padding: .75rem 1.5rem;
    font-family: var(--font-default-bold);
    letter-spacing: .15rem;
    font-size: 1rem;
    line-height: 1;
    border:0;
    @media screen and (min-width: 768px) {
        font-size: 1.125rem;
        padding: 1rem 2.5rem;
    }
}
.btn .icon{
    font-size: 1rem;
    position: relative;
    top:1px;
    margin-right:.125rem;
}
.btn-trans{
    color: var(--color-primary);
    letter-spacing: 0;
    background: transparent;
}
.btn-rounded {
    border-radius: calc(10rem / 16);
}
.btn-primary{
    color: #fff;
    background: linear-gradient(229.09deg, #01D0E8 0%, #5F19EB 75%, #7f21d9 100%);
}
.btn-primary:hover,
.btn-primary:focus{
    color: #fff;
    background: linear-gradient(229.09deg, #01D0E8 0%, #5F19EB 50%, #7f21d9 100%);
}
.btn-secondary{
    color: #fff;
    letter-spacing: 0;
    font-family: var(--font-default);
    background: var(--color-primary);
}
.btn-secondary:hover,
.btn-secondary:focus{
    color: #fff;
    background: #000;
}
.btn-icon .icon {
    position: relative;
    top:calc(3rem/16);
    margin-right: .25rem;
    font-size: 1.25rem;
}
.btn.btn-no-styling{
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}
.btn.btn-plain-icon{
    position: relative;
    padding:calc(5rem/16) 0;
    display: inline-block;
    font-size: calc(18rem/16);
    color:inherit;
}
.btn-plain-icon + .btn-plain-icon{
    margin-left:1rem;
    @media screen and (min-width: 768px) {
        margin-left:2rem;
    }
}
.btn-plain-icon:hover,
.btn-plain-icon:focus{
    color:inherit;
}
.btn-plain-icon:before,
.btn-plain-icon:before{
    content:'';
    position: absolute;
    left:0;
    bottom:0;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.2s ease-in-out;
    width: 100%;
    height: .125rem;
    display: block;
    background: linear-gradient(229.09deg, #01D0E8 0%, #5F19EB 57.68%, #ED389D 100%);
}
.btn-plain-icon:hover:before,
.btn-plain-icon:focus:before{
    visibility: visible;
    transform: scaleX(1);
}

