@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1626172612211/static/debug/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1626172612211/static/debug/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1626172612211/static/debug/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-chat:before { content: "\EA01" }
.icon-check:before { content: "\EA02" }
.icon-circle-check:before { content: "\EA03" }
.icon-circle-x:before { content: "\EA04" }
.icon-clock:before { content: "\EA05" }
.icon-cloud-download:before { content: "\EA06" }
.icon-cloud-upload:before { content: "\EA07" }
.icon-cloud:before { content: "\EA08" }
.icon-collapse-down:before { content: "\EA09" }
.icon-credit-card:before { content: "\EA0A" }
.icon-data-transfer-download:before { content: "\EA0B" }
.icon-document:before { content: "\EA0C" }
.icon-download:before { content: "\EA0D" }
.icon-dropdown:before { content: "\EA0E" }
.icon-ellipses:before { content: "\EA0F" }
.icon-eye:before { content: "\EA10" }
.icon-folder:before { content: "\EA11" }
.icon-home:before { content: "\EA12" }
.icon-info:before { content: "\EA13" }
.icon-laptop:before { content: "\EA14" }
.icon-link:before { content: "\EA15" }
.icon-list-rich:before { content: "\EA16" }
.icon-locked-edit:before { content: "\EA17" }
.icon-login:before { content: "\EA18" }
.icon-logout:before { content: "\EA19" }
.icon-menu:before { content: "\EA1A" }
.icon-pencil:before { content: "\EA1B" }
.icon-person:before { content: "\EA1C" }
.icon-reload:before { content: "\EA1D" }
.icon-send:before { content: "\EA1E" }
.icon-spreadsheet:before { content: "\EA1F" }
.icon-star:before { content: "\EA20" }
.icon-sun:before { content: "\EA21" }
.icon-upload:before { content: "\EA22" }


:root {
--icon-chat: "\EA01";
    --icon-check: "\EA02";
    --icon-circle-check: "\EA03";
    --icon-circle-x: "\EA04";
    --icon-clock: "\EA05";
    --icon-cloud-download: "\EA06";
    --icon-cloud-upload: "\EA07";
    --icon-cloud: "\EA08";
    --icon-collapse-down: "\EA09";
    --icon-credit-card: "\EA0A";
    --icon-data-transfer-download: "\EA0B";
    --icon-document: "\EA0C";
    --icon-download: "\EA0D";
    --icon-dropdown: "\EA0E";
    --icon-ellipses: "\EA0F";
    --icon-eye: "\EA10";
    --icon-folder: "\EA11";
    --icon-home: "\EA12";
    --icon-info: "\EA13";
    --icon-laptop: "\EA14";
    --icon-link: "\EA15";
    --icon-list-rich: "\EA16";
    --icon-locked-edit: "\EA17";
    --icon-login: "\EA18";
    --icon-logout: "\EA19";
    --icon-menu: "\EA1A";
    --icon-pencil: "\EA1B";
    --icon-person: "\EA1C";
    --icon-reload: "\EA1D";
    --icon-send: "\EA1E";
    --icon-spreadsheet: "\EA1F";
    --icon-star: "\EA20";
    --icon-sun: "\EA21";
    --icon-upload: "\EA22";
    
}