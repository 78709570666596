:root {
    --color-primary: #5F19EB;
    --color-secondary: #6c757d;
    --color-text-default: #0B0B0C;
    --color-text-muted: #6c757d;

    --color-default: #0B0B0C;
    --color-white: #FFF;
    --color-light-grey: #A1A0AC;
    --color-dark-grey: #6B6A79;
    --color-dark: #0B0B0C;

    --color-success: #28A745;
    --color-info: #17A2B8;
    --color-warning: #DF8D07;
    --color-info-blue: #17A2B8;
    --color-danger: #DC3545;

    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #5518cf;
    --color-secondary-dark: #474b52;
    --color-success-dark: #12862B;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-primary-light: #661bff;
    --color-secondary-light: #88939d;
    --color-success-light: #1EE048;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;

    --font-default: "FFDINWebProRegular", sans-serif;
    --font-default-bold: "FFDINWebProBold", sans-serif;

    --font-size-default: 1.125rem;
    --font-size-default-mobile: 1rem;
}
