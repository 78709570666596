html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
    scroll-behavior: smooth;
}
body {
    position: relative;
    background:#FAFAFB;
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}
.container.container {
    max-width: calc(960rem/16);
    width: 100%;
    @media screen and (max-width: 767px) {
        padding-left:2rem;
        padding-right:2rem;
    }
}
.container.container--wide {
    max-width: calc(1460rem/16);
}
.container.container--medium {
    max-width: calc(1330rem/16);
}
.container.container--narrow {
    max-width: calc(700rem/16);
}
.container.container--extra-narrow {
    max-width: calc(500rem/16);
}
.main-content {
    position: relative;
    color:#fff;
    padding:9rem 0 1px 0;
    background-color: #000;
    background-image: url(/static/img/cpp/pattern-right-top.png), url(/static/img/cpp/pattern-left-bottom.png);
    background-position: right top, left bottom;
    background-repeat: no-repeat, no-repeat;

    @media screen and (min-width: 768px) {
        padding-top:11rem;
        min-height:calc(100vh - 4.5rem);
    }
}
.main-content__headlines {
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
}
.main-content__img {
    position: absolute;
    right:0;
    top:0;
    display: inline-block;
    width:500px;
    height:auto;
    pointer-events: none;
    @media screen and (min-width: 768px) {
        width:700px;
    }
}
.main-content__body {
    position: relative;
}
.main-content__headlines {
    margin:0 0 calc(50rem/16) 0;
    @media screen and (min-width: 768px) {
        margin:0 0 calc(40rem/16) 0;
    }
}
.main-content__headlines--portal {
    @media screen and (min-width: 768px) {
        margin:0 0 calc(65rem/16) 0;
    }
    @media screen and (min-width: 1500px) {
        margin:0 0 calc(100rem/16) 0;
    }
}