.form-group {
    position: relative;
    margin-bottom:2rem;
}
.form-text {
    margin-top:.875rem;
}
.form-control-wrapper {
    padding:.125rem;
    background: linear-gradient(229.09deg, #01D0E8 0%, #5F19EB 75%, #7f21d9 100%);
}
.form-control-wrapper--narrow {
    width:100%;
    max-width:14rem;
}
.form-group > label,
.form-control-label {
    font-size: .75rem;
}
.custom-file {
    position: relative;
    height: 3.5rem;
}
.custom-file-input,
.custom-file-label,
.form-control {
    border:0;
    color:var(--color-text-default);
    font-size:1.125rem;
    letter-spacing: 1px;
    height: 3.5rem;
    padding: .875rem 1.125rem;
    background:#fff;
    @media screen and (max-width: 767px) {
        font-size:1rem;
    }
}
.form-control--blank {
    box-shadow: none !important;
    border:0;
}
.form-control--tiny {
    display: inline-block;
    height:1.5rem;
    width: auto;
    min-width:2rem;
    padding:.375rem 1rem;
    font-size:.875rem;
    font-family: var(--font-default-bold);
}
.form-control.form-control--message {
    font-size:1rem;
    letter-spacing: 0;
    box-shadow: none;
    padding-right: 4.5rem;
    border:1px solid #E8E8EC;
}
.custom-file-input {
    height: 5rem;
}
.file-input__text {
    color:var(--color-text-default);
    position: absolute;
    left:1.125rem;
    top:2.625rem;
    z-index: 1;
}
.custom-file-label:after {
    height: 3.5rem;
    font-size: .875rem;
    font-family: var(--font-default-bold);
    padding: 1.125rem;
}
.form-control::-webkit-input-placeholder { /* Edge */
    color:var(--color-text-default);
}
.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:var(--color-text-default);
}
.form-control::placeholder {
    color:var(--color-text-default);
}